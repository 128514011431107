import React from 'react';

import Wrapper from 'components/Wrapper';

const questions = [
	{
		q: 'How do payments work?',
		a: 'YouPos works with a number of leading payment providers, including WorldPay, Elavon and Stripe. Your provider can be directly integrated into your YouPos store, so there\'s no need for a separate account - keep everything in one place! Customers can pay via standard card payments, or Apple Pay/Google Pay where supported.'
	},
	{
		q: 'Can customers pay using cash?',
		a: 'No - online payment via Apple Pay, Google Pay, or credit/debit card is required to complete an order.'
	},
	{
		q: 'Do I need a POS (Point of Sale) System?',
		a: 'Nope - YouPos works independently of your existing POS system. If you need a custom integration, let us know and we can provide a quote.'
	},
	{
		q: 'Do I need to buy any hardware?',
		a: 'YouPos is completely cloud-based - meaning you can access your private admin area from any computer or tablet.'
	},
	{
		q: 'Is any software required?',
		a: 'The customer-facing menus and the store administration area are all accessed via a standard web browser - even via your smartphone or tablet. This works across all modern devices and operating systems.'
	},
	{
		q: 'Do I need my own website?',
		a: 'Nope - YouPos provides a unique URL for your business as part of the signup process. If you have a website, you can link directly to this so that users can browse without needing to scan the QR codes.'
	},
	{
		q: 'How do I manage my orders?',
		a: 'YouPos provides an easy to use administration area, where you can manage your products, categories, promotions, stock and more!'
	},
];

const USPs = () => {
	return (
		<section className="o-section c-faqs" id="faqs">
			<Wrapper narrow>
				<header className="o-section__header">
					<h2>Frequently Asked Questions</h2>
					<p>See below for a list of common questions about our table ordering system - please use the contact form below if you have any other queries!</p>
				</header>
			</Wrapper>
			<Wrapper narrow>
				<div className="c-accordion">
					{questions.map(q => {
						return (
							<details class="c-accordion__item">
								<summary>
									<div className="c-accordion__header">
										<span class="c-accordion__title h5">{q.q}</span>
										<span class="c-accordion__toggle"></span>
									</div>
								</summary>
								<div class="c-accordion__content">
									<p>{q.a}</p>
								</div>
							</details>
						)
					})}
				</div>
			</Wrapper>
		</section>
	)
}

export default USPs;
import React from 'react';

import Wrapper from 'components/Wrapper';

const RegisterInterest = () => {
	return (
		<section className="o-section c-how-it-works s-standard-content" id="register-interest">
			<Wrapper narrow>
				<header className="o-section__header">
					<h2>Register Your Interest</h2>
					<p>YouPos is currently in beta. If you'd like run a trial at your venue, or want to receive updates on future releases, please fill in the simple form below - there's no obligation at this stage.</p>
					<p>Please email us at <a href="mailto:hello@youpos.co.uk">hello@youpos.co.uk</a> for any other enquiries!</p>
				</header>
			</Wrapper>
			<Wrapper narrow>
				<form method="post" action="/register-success/" netlify-honeypot="bot-field" data-netlify="true" className="c-register-form" name="register_interest">
					<input type="hidden" name="bot-field" aria-label="Bot Field" />
					<div className="o-field">
						<label htmlFor="full_name" className="o-label">Your Name</label>
						<input type="text" className="o-input" name="full_name" id="full_name" aria-label="Your Name" required />
					</div>
					<div className="o-field">
						<label htmlFor="email_address" className="o-label">Email Address</label>
						<input type="email" className="o-input" name="email_address" id="email_address" aria-label="Email Address" required />
					</div>
					<div className="o-field">
						<label htmlFor="company_name" className="o-label">Company Name <span className="o-label__optional-flag">(optional)</span></label>
						<input type="text" className="o-input" name="company_name" id="company_name" aria-label="Company (optional)" />
					</div>
					<div className="o-field">
						<label htmlFor="message" className="o-label">Message <span className="o-label__optional-flag">(optional)</span></label>
						<textarea className="o-input" name="message" id="message" aria-label="Message (optional)" rows="5"></textarea>
					</div>
					<div className="o-field">
						<button type="submit" className="c-button">Send</button>
					</div>
				</form>
			</Wrapper>
		</section>
	)
}

export default RegisterInterest;
import React from 'react';

import Wrapper from 'components/Wrapper';

import { IoIosBuild } from "react-icons/io";
import { IoMdListBox } from "react-icons/io";
import { IoIosQrScanner } from "react-icons/io";
import { IoIosCard } from "react-icons/io";

const HowItWorks = () => {
	return (
		<section className="o-section c-how-it-works" id="how-it-works">
			<Wrapper narrow>
				<header className="o-section__header">
					<h2>How it Works</h2>
					<p>YouPos is an online ordering service for the hospitality industry, where users can place orders directly from their table - no apps, no hardware and no logins. Anyone can register their bar, pub or coffee shop and get started&nbsp;today!</p>
				</header>
			</Wrapper>
			<Wrapper>
				<div className="o-grid">
					<div className="o-grid__col o-grid__col-3 o-grid__col-6@ds o-grid__col-12@tm">
						<IoIosBuild className="c-how-it-works__icon" />
						<h3>Setup Your Store</h3>
						<p>Get up and running today - no installations or engineers</p>
					</div>
					<div className="o-grid__col o-grid__col-3 o-grid__col-6@ds o-grid__col-12@tm">
						<IoMdListBox className="c-how-it-works__icon" />
						<h3>Add Your Menus</h3>
						<p>Add categories, products and promotions</p>
					</div>
					<div className="o-grid__col o-grid__col-3 o-grid__col-6@ds o-grid__col-12@tm">
						<IoIosQrScanner className="c-how-it-works__icon" />
						<h3>Print QR Codes</h3>
						<p>Add tables and get a unique QR code for each</p>
					</div>
					<div className="o-grid__col o-grid__col-3 o-grid__col-6@ds o-grid__col-12@tm">
						<IoIosCard className="c-how-it-works__icon" />
						<h3>Take Orders</h3>
						<p>Orders go into your account and are viewable in any browser</p>
					</div>
				</div>
				<div className="o-section__footer">
					<a href="#usps" className="c-button c-button--ghost">Why YouPos?</a>
				</div>
			</Wrapper>
		</section>
	)
}

export default HowItWorks;
import React from 'react';

import Wrapper from 'components/Wrapper';

const Hero = ({
	image,
	title,
	subtitle,
	linkUrl,
	linkText,
	imageClass,
}) => {

	return (
		<div className="c-hero o-media t-dark">
			<img src={image} alt="" className={`c-hero__bg-image u-cover ${imageClass}`} />
			<Wrapper className="c-hero__wrapper" narrow>
				<div className="c-hero__content">
					<h1>{title}</h1>
					{subtitle && <h2 className="c-hero__subtitle">{subtitle}</h2>}
					{linkUrl && linkText && <a href={linkUrl} className="c-button c-button--ghost-white">{linkText}</a>}
				</div>
			</Wrapper>
		</div>
	)
};

export default Hero;
import React from 'react';

import Wrapper from 'components/Wrapper';

import { IoIosCheckmarkCircleOutline } from "react-icons/io";

const USPs = () => {
	return (
		<section className="o-section c-usps t-dark" id="usps">
			<Wrapper narrow>
				<header className="o-section__header">
					<h2>Why YouPos?</h2>
				</header>
			</Wrapper>
			<Wrapper>
				<div className="o-grid">
					<div className="o-grid__col o-grid__col-4 o-grid__col-6@ds o-grid__col-12@tm c-usps__item">
						<IoIosCheckmarkCircleOutline className="c-usps__icon" />
						<p className="c-usps__text">No apps to download - users can access your store at any time</p>
					</div>
					<div className="o-grid__col o-grid__col-4 o-grid__col-6@ds o-grid__col-12@tm c-usps__item">
						<IoIosCheckmarkCircleOutline className="c-usps__icon" />
						<p className="c-usps__text">Reduce queue times and staff costs</p>
					</div>
					<div className="o-grid__col o-grid__col-4 o-grid__col-6@ds o-grid__col-12@tm c-usps__item">
						<IoIosCheckmarkCircleOutline className="c-usps__icon" />
						<p className="c-usps__text">Comply with social distancing - keep your business and customers safe</p>
					</div>
					<div className="o-grid__col o-grid__col-4 o-grid__col-6@ds o-grid__col-12@tm c-usps__item">
						<IoIosCheckmarkCircleOutline className="c-usps__icon" />
						<p className="c-usps__text">Cloud-based management - manage your online store from anywhere</p>
					</div>
					<div className="o-grid__col o-grid__col-4 o-grid__col-6@ds o-grid__col-12@tm c-usps__item">
						<IoIosCheckmarkCircleOutline className="c-usps__icon" />
						<p className="c-usps__text">Simple, flat rate payment processing for all transactions</p>
					</div>
					<div className="o-grid__col o-grid__col-4 o-grid__col-6@ds o-grid__col-12@tm c-usps__item">
						<IoIosCheckmarkCircleOutline className="c-usps__icon" />
						<p className="c-usps__text">Payments handled securely by Stripe</p>
					</div>
				</div>
				<div className="o-section__footer">
					<a href="#register-interest" className="c-button c-button--ghost-white">Register Your Interest</a>
				</div>
			</Wrapper>
		</section>
	)
}

export default USPs;
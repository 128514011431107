import React from 'react';
import { Helmet } from 'react-helmet';

import Layout from 'components/Layout';
import Hero from 'components/Hero';
import HowItWorks from 'components/HowItWorks';
import USPs from 'components/USPs';
import RegisterInterest from 'components/RegisterInterest';
import FAQs from 'components/FAQs';

import img_table from '../assets/images/table.jpg';

const IndexPage = () => {
    return (
        <Layout pageName="home">
            <Helmet>
                <title>QR-Based Table Ordering System for Bars and Coffee Shops</title>
                <meta name="description" content="YouPos is an on-table ordering system for bars and coffee shops. Allow customers to order from their tables using QR codes, and manage orders from any device!" />
                <link rel="canonical" href="https://www.youpos.co.uk" />
            </Helmet>
            <Hero 
                image={img_table}
                title="Simple, effective, table ordering system for bars, restaurants and coffee shops"
                subtitle="Encourage social distancing and reduce queue times with YouPos. Simply create an account and add QR codes to your tables to get started today - no apps or software required!"
                linkUrl="/how-it-works"
                linkText="Find Out More"
            />
            <HowItWorks />
            <USPs />
            <FAQs />
            <RegisterInterest />
        </Layout>
    );
};

export default IndexPage;
